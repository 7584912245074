<template>
  <div>
    <!-- Feature Grid Section -->
    <schedule
      :title="$t('Views.FrontPublications.scheduleTitle')"
      :button1="$t('Views.FrontPublications.scheduleButton1')"
      :button2="$t('Views.FrontPublications.scheduleButton2')"
      @updateSelectedPublication="handleSelectedPublication($event)"
    />
    <!-- <schedule-2
      :title="'Agenda FIAF'"
      @updateSelectedPublication="handleSelectedPublication($event)"
    ></schedule-2> -->
    <news
      id="news"
      :title="$t('Views.FrontPublications.newsTitle')"
      @updateSelectedPublication="handleSelectedPublication($event)"
    />
    <events
      id="events"
      :limitOfItems="20"
      @updateSelectedPublication="handleSelectedPublication($event)"
    />

    <!-- Modal for the contend of publications -->
    <modal
      id="content-modal"
      name="contentModal"
      height="85%"
      width="80%"
      :adaptive="true"
      :focusTrap="true"
      @before-open="beforeOpenModal"
      @before-close="beforeCloseModal"
    >
      <v-row no-gutters class="post-container h-100 p-4">
        <v-btn
          @click="
            beforeCloseModal
            $modal.hide('contentModal')
          "
          :icon="true"
          absolute
          top
          right
          style="z-index: 9"
          outlined
          alt="Read more"
          color="#001c6c"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <div class="col-11 col-sm-5 mx-auto mx-sm-0 p-3">
          <!-- Img -->
          <div style="display: flex; justify-content: center; align-items: center">
            <v-img
              class="post-img img-fluid"
              style="width: 100%"
              :alt="publication.title"
              v-if="publication.cover && publication.cover.route"
              :src="publication.cover.route"
            ></v-img>
          </div>
        </div>
        <!-- CONTENT -->
        <div class="post-content h-100 col-12 col-sm-7 p-3">
          <h1 class="text-center h3 mx-3">{{ publication.title }}</h1>
          <countries-flags
            :countries="
              publication.countries && publication.countries.length > 0
                ? publication.countries
                : []
            "
          ></countries-flags>
          <ul class="post-meta my-2" v-if="publication.type !== 'events'">
            <li v-if="publication.date">
              {{
                new Date(publication.date).toLocaleDateString('es-VE', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              }}
            </li>
            <li>
              {{ publication.author }}
            </li>
          </ul>
          <p v-if="publication.type === 'events'">
            <span v-if="publication.address"
              ><strong>Ubicación:</strong> {{ publication.address }}</span
            ><br v-if="publication.address" />
            <span v-if="publication.dateRange > 0 && publication.startAt"
              ><strong>Inicia:</strong> {{ publication.startAt }}</span
            ><br v-if="publication.dateRange > 0 && publication.startAt" />
            <span v-if="publication.dateRange > 0 && publication.expired_at"
              ><strong>Culmina:</strong> {{ publication.endAt }}</span
            ><br v-if="publication.dateRange > 0 && publication.expired_at" />
            <span v-if="publication.dateRange == 0"
              ><strong>Fecha:</strong> {{ publication.startAt }}</span
            ><br v-if="publication.dateRange == 0" />
            <span v-if="publication.modality"
              ><strong>Modalidad:</strong> {{ publication.modalityText }}</span
            >
          </p>

          <div class="post-text text-box" v-html="publication.content"></div>
        </div>
      </v-row>
    </modal>
  </div>
</template>

<script>
  import Schedule from 'Components/FrontendComponents/Schedule'
  import Events from 'Components/FrontendComponents/Events'
  import News from 'Components/FrontendComponents/News'
  import CountriesFlags from '@/components/FrontendComponents/CountriesFlags.vue'

  export default {
    name: 'Publications',
    data() {
      return {
        publication: [],
      }
    },
    components: { Schedule, Events, News, CountriesFlags },
    methods: {
      handleSelectedPublication(publication) {
        this.$modal.show('contentModal')
        this.publication = publication
      },
      beforeOpenModal() {
        // When the modal is shown, we want a fixed body
        const topBody = window.scrollY
        document.body.style.position = 'fixed'
        document.body.style.top = `-${topBody}px`
      },
      beforeCloseModal() {
        // When the modal is hidden...
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.top = ''
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/third-party/slick-slider.scss';
  .post-container {
    overflow: auto;
    height: calc(100% - 200px);
  }
  .pointer {
    cursor: pointer;
  }
</style>
